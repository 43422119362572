import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { ImageDataForm } from './ImageDataForm';
import { Thumbs } from './Thumbs';
import { useProductFormState } from '../ProductFormState';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../Auth/hooks/useAuth';
import { useLightbox } from '../../../../../components/SimpleReactLightboxExtended';
import {
    createImages,
    createImagesFromMedias,
    deleteImage,
    updateImage,
    updateImages,
    flipImage,
} from '../../../../../API/product/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import useIsMobile from '../../../../../hooks/useIsMobile';
import './ImagesForm.scss';

export const ImagesForm = () => {
    const { projectId, productId } = useParams();
    const { organisation } = useAuth();

    const { t } = useTranslation();

    const {
        setProductImages: setFiles,
        productImages: files,
        product_images,
        setFormState,
    } = useProductFormState();

    const [fileSelected, setFileSelected] = useState<number>(-1);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const newFiles: any[] =
                acceptedFiles.map((file: any, index: number) => {
                    return Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        index: files.length + index,
                        sort_order: files.length,
                        is_public: true,
                    });
                }) || [];

            if (organisation?.id && projectId && productId) {
                createImages(
                    organisation?.id,
                    parseInt(projectId, 10),
                    parseInt(productId, 10),
                    newFiles
                ).then((res) => {
                    setFormState((prev) => ({
                        ...prev,
                        productImages: createImagesFromMedias(res.medias),
                        product_images: res,
                    }));
                });
            } else {
                setFiles((prev: any[]) => [...prev, ...newFiles]);
            }
        },
        [
            files.length,
            organisation?.id,
            productId,
            projectId,
            setFiles,
            setFormState,
        ]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/png, image/jpg, image/jpeg, image/webp',
        onDrop,
    });

    const selectImage = useCallback((index: number) => {
        setFileSelected(index);
    }, []);

    const changeImageText = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (fileSelected > -1) {
                setFiles((prev: any[]) => [
                    ...prev.slice(0, fileSelected),

                    Object.assign(prev[fileSelected], {
                        title: e.target.value,
                    }),

                    ...prev.slice(fileSelected + 1),
                ]);
            }
        },
        [fileSelected, setFiles]
    );

    const onBlurImageText = useCallback(() => {
        if (fileSelected > -1) {
            if (organisation && projectId && productId && product_images?.id) {
                const file = files[fileSelected];

                updateImage(
                    organisation?.id,
                    parseInt(projectId, 10),
                    parseInt(productId, 10),
                    product_images?.id,
                    file
                );
            }
        }
    }, [
        fileSelected,
        files,
        organisation,
        productId,
        product_images?.id,
        projectId,
    ]);

    const changeImagePublic = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (fileSelected !== null && fileSelected !== -1) {
                if (
                    organisation &&
                    projectId &&
                    productId &&
                    product_images?.id
                ) {
                    const file = {
                        ...files[fileSelected],
                        is_public: e.target.checked,
                    };

                    updateImage(
                        organisation?.id,
                        parseInt(projectId, 10),
                        parseInt(productId, 10),
                        product_images?.id,
                        file
                    ).then(() => {
                        setFiles((prev: any[]) => {
                            return [
                                ...prev.slice(0, fileSelected),
                                {
                                    ...prev[fileSelected],
                                    ...file,
                                },
                                ...prev.slice(fileSelected + 1),
                            ];
                        });
                    });
                } else {
                    setFiles((prev: any[]) => {
                        return [
                            ...prev.slice(0, fileSelected),
                            Object.assign(prev[fileSelected], {
                                is_public: e.target.checked,
                            }),
                            ...prev.slice(fileSelected + 1),
                        ];
                    });
                }
            }
        },
        [
            fileSelected,
            files,
            organisation,
            productId,
            product_images?.id,
            projectId,
            setFiles,
        ]
    );

    const handleMove = useCallback(
        (toIndex: number, fromIndex: number) => {
            // move card to hover position
            setFiles((prev: any[]) => {
                const prevFiles = [...prev];

                // moving an item from hoverIndex to dragIndex
                const removedElement = prevFiles.splice(fromIndex, 1);
                prevFiles.splice(toIndex, 0, ...removedElement);

                // TODO: needs to handel reselection of toIndex,
                // setting toIndex here results in selecting empty slot
                // Will just deselect for now
                setFileSelected(-1);

                return [...prevFiles];
            });
        },
        [setFiles]
    );

    const handleRemove = useCallback(
        (index: number) => {
            if (organisation && projectId && productId && product_images?.id) {
                deleteImage(
                    organisation?.id,
                    parseInt(projectId, 10),
                    parseInt(productId, 10),
                    product_images.id,
                    files[fileSelected]
                ).then(() => {
                    setFiles((prev: any[]) => {
                        setFileSelected(-1);
                        return [
                            ...prev.slice(0, index),
                            ...prev.slice(index + 1),
                        ];
                    });
                });
            } else {
                setFiles((prev: any[]) => {
                    setFileSelected(-1);
                    return [...prev.slice(0, index), ...prev.slice(index + 1)];
                });
            }
        },
        [
            fileSelected,
            files,
            organisation,
            productId,
            product_images?.id,
            projectId,
            setFiles,
        ]
    );

    const handleFlip = useCallback(
        (index: number, direction: 'h' | 'v' | 'r' | 'c') => {
            if (
                !(
                    organisation?.id &&
                    projectId &&
                    productId &&
                    files?.length &&
                    direction
                )
            )
                return;
            flipImage(
                organisation?.id,
                projectId,
                productId,
                files[index].id,
                direction
            ).then(() => {
                const img = document.querySelector(
                    `#image-${index}`
                ) as HTMLImageElement;
                if (img.src.indexOf('?') > -1)
                    img.src = img.src.slice(0, img.src.indexOf('?') + 1);
                img.src += '?' + Date.now();
            });
        },
        [organisation?.id, projectId, productId, files]
    );

    const updateOrder = useCallback(
        (file: any) => {
            if (organisation && projectId && productId && product_images?.id) {
                updateImages(
                    organisation?.id,
                    parseInt(projectId, 10),
                    parseInt(productId, 10),
                    product_images.id,
                    files
                );
            }
        },
        [files, product_images?.id, organisation, productId, projectId]
    );

    const isMobile = useIsMobile();
    const selected =
        fileSelected > -1 && files[fileSelected] ? files[fileSelected] : null;

    const { showLightboxImages } = useLightbox();
    const showLightbox = useCallback(
        () => showLightboxImages({ medias: files }, fileSelected),
        [files, fileSelected, showLightboxImages]
    );

    return (
        <Row className='d-flex flex-row'>
            <Col
                {...getRootProps({
                    className:
                        'dropzone cursor-pointer m-2 card bg-light text-center border-0',
                })}
                md={12}
                xl={3}
            >
                <input {...getInputProps()} />
                <div className='m-sm-4 fs-1'>
                    <FontAwesomeIcon icon={faCloudUpload} />
                </div>
                <h5>{t('Upload images')}</h5>
                <p className='d-none d-sm-block'>
                    {t('Drag one or more files here, or click to select files')}
                </p>
            </Col>
            <Col
                className='d-flex flex-column align-items-center'
                md={12}
                xl={6}
            >
                <div className='thumbs-container'>
                    <DndProvider
                        backend={isMobile ? TouchBackend : HTML5Backend}
                    >
                        <Thumbs
                            files={files}
                            selected={fileSelected}
                            select={selectImage}
                            handleMove={handleMove}
                            onDrop={updateOrder}
                        ></Thumbs>
                    </DndProvider>
                </div>
            </Col>
            <Col md={12} xl={3}>
                {
                    <ImageDataForm
                        selected={selected}
                        changeImageText={changeImageText}
                        onBlurImageText={onBlurImageText}
                        changeIsPublic={changeImagePublic}
                        remove={() => handleRemove(fileSelected)}
                        flip={(d) => handleFlip(fileSelected, d)}
                        showLightbox={showLightbox}
                    ></ImageDataForm>
                }
            </Col>
        </Row>
    );
};
