import axios from 'axios';
import { IImageMedia } from '../../types/IMediaType';
import { imageSortFn } from '../../utils/helper';

export const createImages = async <T = never>(
    organisationId: number,
    projectId: number,
    productId: number,
    productImages: any[]
) => {
    const productImagesFormData = new FormData();

    productImages.forEach((image: any, index: number) => {
        productImagesFormData.append(
            `product_gallery[${index}]`,
            productImages[index]
        );

        productImagesFormData.append(
            `info[product_gallery][${index}][title]`,
            image?.title || ''
        );
        productImagesFormData.append(
            `info[product_gallery][${index}][description]`,
            image?.description || ''
        );
        productImagesFormData.append(
            `info[product_gallery][${index}][sort_order]`,
            index.toString()
        );
        productImagesFormData.append(
            `info[product_gallery][${index}][is_public]`,
            (image?.is_public ? 1 : 0).toString()
        );
    });

    const imagesResponse = await axios
        .post<T>(
            `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media`,
            productImagesFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        .then((response) => {
            return axios
                .get(
                    `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}`
                )
                .then((response) => {
                    return response.data.data.product_images;
                });
        });

    if (imagesResponse) {
        return imagesResponse;
    }
};

export const updateImages = async (
    organisationId: number,
    projectId: number,
    productId: number,
    mediaGroup: number,
    productImages: any[]
) => {
    const productImagesFormData = new FormData();

    productImages.forEach((image: any, index: number) => {
        productImagesFormData.append(
            `info[product_gallery][${image.id}][title]`,
            image?.title || ''
        );
        productImagesFormData.append(
            `info[product_gallery][${image.id}][description]`,
            image?.description || ''
        );
        productImagesFormData.append(
            `info[product_gallery][${image.id}][sort_order]`,
            index.toString()
        );
        productImagesFormData.append(
            `info[product_gallery][${image.id}][is_public]`,
            (image?.is_public ? 1 : 0).toString()
        );
    });

    productImagesFormData.append('_method', 'PUT');

    const imagesResponse = await axios.post(
        `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media/${mediaGroup}`,
        productImagesFormData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (imagesResponse) {
        return imagesResponse.data;
    }
};

export const updateImage = async (
    organisationId: number,
    projectId: number,
    productId: number,
    mediaGroup: number,
    image: any
) => {
    const productImagesFormData = new FormData();

    productImagesFormData.append(
        `info[product_gallery][${image.id}][title]`,
        image?.title || ''
    );
    productImagesFormData.append(
        `info[product_gallery][${image.id}][description]`,
        image?.description || ''
    );
    productImagesFormData.append(
        `info[product_gallery][${image.id}][sort_order]`,
        image.sort_order.toString()
    );
    productImagesFormData.append(
        `info[product_gallery][${image.id}][is_public]`,
        (image?.is_public ? 1 : 0).toString()
    );

    productImagesFormData.append('_method', 'PUT');

    const imagesResponse = await axios.post(
        `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media/${mediaGroup}`,
        productImagesFormData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (imagesResponse) {
        return imagesResponse.data;
    }
};

export const deleteImage = async (
    organisationId: number,
    projectId: number,
    productId: number,
    mediaGroup: number,
    image: any
) => {
    const imagesResponse = await axios.delete(
        `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media/media-${image.id}`
    );

    if (imagesResponse) {
        return imagesResponse.data;
    }
};

export function createImagesFromMedias(medias: IImageMedia[]): any[] | never[] {
    return medias.sort(imageSortFn).map((media) => {
        return {
            id: media.id,
            preview: process.env.REACT_APP_API_ENDPOINT + media.url.thumbnail,
            url: media.url,
            title: media.title,
            is_public: media.is_public,
            sort_order: media.sort_order,
        };
    });
}

export async function flipImage(
    organisationId: number,
    projectId: string,
    productId: string,
    imageId: string,
    direction: 'v' | 'h' | 'r' | 'c'
) {
    return axios.patch(
        `/api/organisations/${organisationId}/projects/${projectId}/products/${productId}/media/${imageId}/flip`,
        {
            direction,
        }
    );
}
